/* eslint-disable @nx/enforce-module-boundaries */
import { Avatar } from '@mybridge/ui/avatar';
import { Box } from '@mybridge/ui/layout';
import { useContext, useEffect, useState } from 'react';
import { getUserFullName, getUserProfilePic } from 'v4/lib/commons';
import { ChevronDownIcon } from '@chakra-ui/icons';
import styles from 'v4/snippets/header/index.module.scss';
import { getAllCompanyPages } from 'v4/store/actions/companyPages.actions';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@mybridge/ui/modal';
import { Button } from '@mybridge/ui/button';
import { Heading } from '@mybridge/ui/heading';
import PostAsComponent from 'v4/components/post-form/postAsComponent';
import { useDispatch, useSelector } from 'react-redux';
import { currentProfileComment } from 'v4/store/actions/user-profile.actions';
import Link from 'next/link';
import { PostBoxContext, PostFormContext } from '../context';
import { useDisclosure } from '@chakra-ui/react';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

export const PostCommentAvatar = ({ companyDetails, post, multiPost }) => {
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { loggedIn } = useSelector((state) => state.user);
  const commentPopDisc = useDisclosure();
  const userFullName = getUserFullName(userProfileInfo);
  const profilePic = getUserProfilePic(userProfileInfo);
  const [openPostModal, setOpenPostModal] = useState(false);

  const [selectedUserComment, setSelectedUserComment] = useState(
    userProfileInfo?.id
  );
  const {
    isCompanyComment,
    setIsCompanyComment,
    currentPostCommenter,
    setCurrentPostCommenter,
  } = useContext(PostBoxContext) ?? {};
  const { t } = useTranslator();

  const dispatch = useDispatch();

  const { companyPageList, companyPageCount } = useSelector(
    (state) => state.companyPage
  );

  useEffect(() => {
    if (companyPageList?.length === 0 && loggedIn) {
      dispatch(getAllCompanyPages());
    }
    setCurrentPostCommenter(userProfileInfo);
  }, []);

  return (
    <>
      <Box
        className={styles.avatarBox}
        height={post ? '' : '50px'}
        py={1}
        px={2}
      >
        <Link
          href={
            companyDetails?.name?.length > 0
              ? `/c/${companyDetails?.slug}`
              : '/profile/me'
          }
        >
          <Avatar
            w={post ? '25px' : '35px'}
            h={post ? '25px' : '35px'}
            fontSize={post ? '10px' : '16px'}
            border=".5px solid #ffffff"
            name={
              companyDetails
                ? companyDetails?.name
                  ? companyDetails?.name
                  : companyDetails?.display_name
                : userFullName
            }
            src={
              companyDetails
                ? companyDetails?.image
                  ? companyDetails?.image
                  : companyDetails?.profile_pic
                : profilePic
            }
          />
        </Link>

        {companyPageCount > 0 && (
          <Box
            className={post ? styles.boxIconPost : styles.boxIcon}
            onClick={() => commentPopDisc.onOpen()}
          >
            <ChevronDownIcon width="18px" height="18px" />
          </Box>
        )}
      </Box>
      <Modal {...commentPopDisc} isCentered size={["sm","xl"]}>
        <ModalOverlay />
        <ModalContent>
          <form>
            <ModalHeader borderBottom={'1px solid #E1E1E1'}>
              <Heading
                className={styles?.alignCenter}
                size="md"
                color="brandGray.800"
                fontWeight="normal"
                fontSize="lg"
              >
                {t('otherModules.comment_as') || "Comment As"}
              </Heading>
            </ModalHeader>
            <ModalCloseButton fontSize={12} />
            <ModalBody>
              <PostAsComponent
                setSelectedUser={setSelectedUserComment}
                selectedUser={selectedUserComment}
                onClose={() => commentPopDisc?.onClose()}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                variant="secondary"
                type="button"
                onClick={() => commentPopDisc?.onClose()}
                mr={3}
              >
                {t('buttons.cancel') || "Cancel"}
              </Button>
              <Button
                onClick={(event) => {
                  event.preventDefault();
                  const selectedProfile = companyPageList.filter(
                    (details) => details?.id == selectedUserComment
                  );
                  setCurrentPostCommenter(selectedProfile?.[0]);
                  dispatch(currentProfileComment(selectedProfile?.[0]));
                  commentPopDisc?.onClose();
                }}
                variant="primary"
                type="submit"
              >
                {t('buttons.save') || 'Save'}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
